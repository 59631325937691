/**
  * Version 3.2
  * Split Sections Code For Squarespace 7.1 & 7.0
  * Copyright Will Myers 
*/
/*On Mobile*/
@media(max-width: 799px){
  body:not(.sqs-edit-mode-active) .wm-split-sections {
    display:flex;
    flex-wrap:wrap;
  }
  body:not(.sqs-edit-mode-active) .wm-split-section {
    width:100% !important;
    flex-grow: 1;
  }
  body:not(.sqs-edit-mode-active) .wm-split-section {
    position:relative !important;
  }
}

/*Sticky Section on Desktop*/
@media(min-width: 799px){
  body:not(.sqs-edit-mode-active) .split-sticky{
    left: 0;
    min-height:100vh !important;
    align-items: start !important;
  }
  body:not(.sqs-edit-mode-active) .split-sticky .content-wrapper{
    position: sticky !important;
    top: var(--wm-header-bottom, 0px);
    transition: top 240ms ease;
  }
  body:not(.sqs-edit-mode-active) .split-sticky .section-background {
    overflow:unset !important;
  }
  body:not(.sqs-edit-mode-active) .split-sticky .section-background > img{
    position: sticky !important;
    max-height: 100vh !important;
    top: 0px;
  }
  body:not(.sqs-edit-mode-active) .split-sticky .section-background > [data-controller="VideoBackgroundNative"], body:not(.sqs-edit-mode-active) .split-sticky .section-background > [data-controller="VideoBackground"], body:not(.sqs-edit-mode-active) .split-sticky .section-background > .section-background-content {
    position: sticky !important;
    height:100%;
    max-height: 100vh !important;
    top: 0px;
  }
}
body:not(.sqs-edit-mode-active) .wm-split-section .content-wrapper{
  box-sizing:border-box !important;
}

/*When In Edit Mode*/
body:not(.sqs-edit-mode-active) .wm-split-section {
  width:100%;
  flex-grow: 1;
}
body:not(.sqs-edit-mode-active) .wm-split-sections {
  display:flex;
  flex-flow:row wrap;
  position:relative;
  gap: var(--gap);
  padding: var(--gap);
}
body.sqs-edit-mode-active section.wm-split-section{
  width:unset !important;
}
body.sqs-edit-mode-active .wm-split-section {
  position:relative !important;
}

/*Remove Height of Code Block*/
body:not(.sqs-edit-mode-active) .remove-height{
  padding:0px !important;
}

/*Adjust First Section Padding*/
@media(min-width: 799px){
  body:not(.sqs-edit-mode-active) #sections .wm-split-sections:first-child .wm-split-section{
    padding-top:var(--wm-header-height, 0px);
  }
}
@media(max-width: 799px) {
  /*For Transparent Headers*/
  body:not(.sqs-edit-mode-active).tweak-transparent-header .sections > :first-child.wm-split-sections {
    padding-top: 0px !important;
  }
  #header[data-current-style*='"headerStyle": "gradient"'] + main .sections > :first-child.wm-split-sections{
    padding-top: 0px !important;
  }
}

/*Default Section Backgrounds*/
.wm-split-section .section-background img, 
.wm-split-section .summary-v2-block img{
  width: 100% !important;
  height: 100% !important;
  left: 0 !important;
  top: 0 !important;
  object-position: var(--position, 50% 50%);
  object-fit: cover;
}

.wm-split-sections:not(:first-child) .page-section:first-child{
  padding-top:unset;
}

/* 7.0 Code */
.wm-split-section.Index-gallery{
  box-sizing:border-box;
}
